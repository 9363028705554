import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import "./account.css";
import HomeIcon from '@material-ui/icons/Home';
import { NavLink} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Account = () => {
  const [name] = useState("");
  const [user] = useState("");

  console.log(name)
  console.log(user)

  return (
    <div>
        <div className="h1">
            <h2>New way to enjoy porn</h2>
        </div>
      <div className="account__body">
          {!user ? (
        <form className="login__body_acc">
              <img
                src="./img/use18.png"
                alt="Logo"
              />
            <div className="h2">
              <h3>Instantly generate AI content that perfectly suits your fantasies</h3>
            </div>
            <NavLink exact to="/Account">
                  <button className="button_acc">Try for free(I am 18+)</button>
            </NavLink>
        </form>
          ) : (
              <div className="login__body">
                 <img style={{height:"150px", borderRadius:"100px"}} src={user.photoURL} alt={user.displayName} />
                 <h5 style={{textAlign:"center",margin:"20px" ,marginTop:"30px"}}>Welcome {user.displayName} Thank You For Signing Up</h5>
                <NavLink exact to="/chats">
                  <IconButton className="header__icon"><HomeIcon />
                  </IconButton>
                </NavLink>
              </div>
          )}
      </div>
        <div className="h3">
            <h2>I'm not 18+</h2>
        </div>
    </div>
  );
};

export default Account;
