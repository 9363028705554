import { Route, Switch,} from 'react-router-dom';
import './App.css';
import Account from './components/Account';
import Share from './components/Share';
import Subscription from './components/Subscription';
import Header from './components/Header'
import TinderCards from './components/TinderCards'

function App() {



  return (
    <div className="app">
      <Switch>

        <Route exact path="/">
          <Header />
          <Account />
        </Route>

        <Route exact path='/Subscription'>
          <Header />
          <Subscription />
        </Route>

        <Route exact path='/Share'>
          <Header />
          <Share />
        </Route>

        <Route exact path="/Account">
          <Header />
          <TinderCards />
        </Route>

      </Switch>
    </div>
  );
}

export default App;
