import React, { useState } from "react";
import "./share.css";
import "react-toastify/dist/ReactToastify.css";
import { useForm, ValidationError } from '@formspree/react';

const Share = () => {
    const [state, handleSubmit] = useForm("mleyqvvd");

    if (state.succeeded) {
        return <p className="h1_share">Thanks for joining!</p>;
    }

  return (
    <div>
        <div className="h1_share">
            <h>We are in beta mode. We will give you free subscription when we go live.</h>
        </div>
      <div className="share__body_share">
        <form className="login__body_share" onSubmit={handleSubmit}>
            <input
                id="email"
                type="email"
                name="email"
                placeholder="Enter your email"
                type="text"
            />
            <div>
                <label htmlFor="email">Email</label>
                <ValidationError prefix="Email" field="email" errors={state.errors}/>
                <button disabled={state.submitting} className="button_share">Submit</button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default Share;
