import React, { useState } from "react";
import "./subscription.css";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Subscription = () => {

  return (
    <div>
        <div className="h2">
            <h2>Upgrade to enjoy unlimited filters & content</h2>
        </div>
      <div className="subscription__body">
        <form className="login__body">
            <img
                src="./img/use18.png"
                alt="Logo"
                srcSet=""
            />

            <img
                src="./img/perMonth.png"
                alt="Logo"
                srcSet=""
            />

            <NavLink exact to="/Share">
                  <button className="button_sub">Upgrade</button>
            </NavLink>
        </form>
      </div>
    </div>
  );
};

export default Subscription;
