import React, { useEffect, useState, useMemo, useRef } from 'react'
import TinderCard from 'react-tinder-card'
import ClearIcon from "@material-ui/icons/Sync";
import "./tindercard.css"
import {IconButton} from "@material-ui/core";
import {NavLink, useHistory} from "react-router-dom";

const db = [
    {
        name: 'Richard Hendricks',
        url: './img/richard.jpg'
    },
    {
        name: 'Erlich Bachman',
        url: './img/erlich.jpg'
    },
    {
        name: 'Monica Hall',
        url: './img/monica.jpg'
    },
    {
        name: 'Jared Dunn',
        url: './img/jared.jpg'
    },
    {
        name: 'Richard Hendricks',
        url: './img/richard.jpg'
    },
    {
        name: 'Erlich Bachman',
        url: './img/erlich.jpg'
    },
    {
        name: 'Monica Hall',
        url: './img/monica.jpg'
    },
    {
        name: 'Jared Dunn',
        url: './img/jared.jpg'
    },
    {
        name: 'Monica Hall',
        url: './img/monica.jpg'
    },
    {
        name: 'Jared Dunn',
        url: './img/jared.jpg'
    },
    {
        name: 'Jared Dunn',
        url: './img/jared.jpg'
    }
]
function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

function Advanced () {

    // const navigate =
    const [people, setPeople] = useState([])

    //const game = database.collection("game");

    useEffect(()=>{
        let p = arrayRange(42069,42472,1).map((i)=>{ return  {url: `https://pornfeed.ai/game/feedai_${i}.png`, random: i}});
        setPeople(getRandom(p, 11));
    },[])


    // useEffect(()=>{
    //
    //       // const randomNum = Math.floor(Math.random() * 10)
    //       game.onSnapshot(snapshot => {
    //         const data = getRandom(snapshot.docs.map(doc => (doc.data())), 15);
    //         setPeople(data);
    //       })
    //       // database.collection('people').onSnapshot(snapshot =>(setPeople(snapshot.docs.map(doc => (doc.data())))))
    //   },[])

    let history = useHistory();

    const [currentIndex, setCurrentIndex] = useState(db.length - 1)

    useEffect(() => {
      if (currentIndex === 0){
        history.push("/Subscription");

      }
    }, [currentIndex]);

    const [lastDirection, setLastDirection] = useState()
    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)

    const childRefs = useMemo(
        () =>
            Array(db.length)
                .fill(0)
                .map((i) => React.createRef()),
        []
    )

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }

    const canGoBack = currentIndex < db.length - 1

    const canSwipe = currentIndex >= 0

    // set last direction and decrease current index
    const swiped = (direction, nameToDelete, index) => {
        setLastDirection(direction)
        updateCurrentIndex(index - 1)
    }

    const outOfFrame = (name, idx) => {
        console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
        // TODO: when quickly swipe and restore multiple times the same card,
        // it happens multiple outOfFrame events are queued and the card disappear
        // during latest swipes. Only the last outOfFrame event should be considered valid
    }

    const swipe = async (dir) => {
        if (canSwipe && currentIndex < db.length) {
            await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
        }
    }

    // increase current index and show card
    const goBack = async () => {
        if (!canGoBack) return
        const newIndex = currentIndex + 1
        updateCurrentIndex(newIndex)
        await childRefs[newIndex].current.restoreCard()
    }

    return (
        <div>
            <div className='cardContainer'>
                {
                    people.map((person, index) =>(
                        <div key={person.random}  className="card__container">
                            <TinderCard
                                ref={childRefs[index]}
                                className="swipe"
                                preventSwipe={['up','down']}
                                key={person.random}
                                onSwipe={(dir) => swiped(dir, person.random, index)}
                                onCardLeftScreen={() => outOfFrame(person.random, index)}
                            >
                                <div style={{backgroundImage:`url(${person.url})`}}
                                     className="card">
                                    <div className="swipeButtons" >
                                        <IconButton onClick={() => swipe('up')}>
                                            <ClearIcon htmlColor="#de0914" style={{ fontSize: 50 }} />
                                            <h3>{currentIndex}</h3>
                                        </IconButton>
                                    </div>
                                </div>
                            </TinderCard>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Advanced
