import React from "react";
import { IconButton } from "@material-ui/core";
import logo from "./logo.png";
import "./header.css";
import { NavLink, useHistory } from "react-router-dom";

const Header = ({ backButton }) => {
  const history = useHistory();
  const back = () => {
    history.replace(backButton);
  };

  return (
    <div>
      <div className="header">
        <NavLink exact to="/">
            <IconButton className="header__icon">
              <img className="header__img" src={logo} alt="logo" />
            </IconButton>
          </NavLink>
      </div>
    </div>
  );
};

export default Header;
